import { gql } from "@apollo/client";

export const GET_ANNOUNCEMENT = gql`
  query AllAnnouncement($memberId: Int) {
    allAnnouncements(memberId: $memberId) {
      id
      message
      buttonLabel
      imageSmall
      imageMedium
      url
      imageSeoTitle
      shouldHideFromSubscribers
      shouldHideFromNonSubscribers
      announcementBarUrl
      announcementBarUrlText
      targetedSlugs
      excludedSlugs
    }
  }
`;
