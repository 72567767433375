import { colors, BREAKPOINT, CONTAINER_MAX_WIDTH, header } from "../styles/variables";
import urlPatterns from "../urls";
import { VALIDATE_REFERRAL_CODE } from "../graphql/mutations";
import { client } from "../graphql/client";
import {
  WINE_CLASS_IDS,
  WINE_COLLECTIONS_WITH_COUNTRY_NAMES,
  SUBSCRIPTION_STATUS_NAME_TO_ID,
  DB_ID_GIFT_DELIVERY_SEND_EMAIL,
  DB_ID_GIFT_DELIVERY_DIY,
  MEMBER_NAVBAR_ITEM_ID_TASTE_PROFILE,
  TASTE_PROFILE_VIEW_STORAGE_KEY,
  WINE_TYPE_IDS,
  COUNTRY_SPECIFIC_SETTINGS,
  IS_PROD,
  NAVIGATION_WINES,
  NAVIGATION_LEARN,
  NAVIGATION_GIFTS,
  NAVIGATION_REWARDS,
} from "./constants";
import { isLoggedIn } from "./auth";
import { logError } from "./logging";
import {
  GIFTS_NAVBAR_ITEM_URL_PATTERNS,
  LEARN_NAVBAR_ITEM_URL_PATTERNS,
  WINES_NAVBAR_ITEM_URL_PATTERNS,
  REWARDS_NAVBAR_ITEM_URL_PATTERNS,
} from "./urls.constants";
import { getCountry, COUNTRY_ID, checkIfUrlIsInUrlGroup } from "./urls.helper";

/**
 * Returns a formatted number with different number of decimals.
 * @param {number} number - number to be transformed
 * @param {number} decimal - number of decimals you want to show
 * @param {boolean} hasThousandSeparator - in case you want to show thousand separator
 * @return {string || number}
 * */
const formatNumber = (number, decimal = 2, hasThousandSeparator = true) => {
  if (hasThousandSeparator && number > 999.99) {
    return parseFloat(Math.round(number * 100) / 100)
      .toFixed(decimal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return parseFloat(Math.round(number * 100) / 100).toFixed(decimal);
};

/**
 * Sets time out with async/await features (useful for testing mutations).
 * @param {number} ms - milliseconds to be waited
 * @return {Promise<any>}
 * */
const wait = (ms) => new Promise((r) => setTimeout(r, ms));

/**
 * Return a greeting depending on the time of the day.
 *
 * @return {string}
 * */
const getTimeGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return "Good morning!";
  if (hour < 17) return "Good afternoon!";
  return "Good evening!";
};

/**
 * Checks if the wine type id is that of 'orange wine'.
 *
 * @param {number} wineTypeId
 * @returns {boolean}
 */
const checkIfWineTypeIsOrangeWine = (wineTypeId) => wineTypeId === WINE_TYPE_IDS.DB_ID_WINE_TYPE_ORANGE_WINE;

/**
 * Gets the theme based on the wine color.
 *
 * @param {number} wineClassId
 * @param {number} wineTypeId
 * @returns {string}
 */
const getWineBaseColor = (wineClassId = null, wineTypeId = null) => {
  // TODO: Replace with Orange Wine class ID when there is enough range to justify it.
  const isOrangeWine = checkIfWineTypeIsOrangeWine(wineTypeId);
  if (isOrangeWine) return colors.peach;

  switch (wineClassId) {
    case WINE_CLASS_IDS.DB_ID_WINE_CLASS_RED:
      return colors.purpleThin;

    case WINE_CLASS_IDS.DB_ID_WINE_CLASS_WHITE:
      return colors.yellow;

    case WINE_CLASS_IDS.DB_ID_WINE_CLASS_ROSE:
      return colors.pinkTint;

    case WINE_CLASS_IDS.DB_ID_WINE_CLASS_SPARKLING:
      return colors.blueThin;

    case WINE_CLASS_IDS.DB_ID_WINE_CLASS_SAKE:
      return colors.successThin;

    default:
      return colors.blueThin;
  }
};

/**
 * Formats numbers as ordinal
 *
 * @param {number} value
 * @returns {string}
 * @example ordinalNumberFormatter(32); // "32nd"
 */
const ordinalNumberFormatter = (value) => {
  if (value % 100 >= 11 && value % 100 <= 20) return `${value}th`;

  const suffix =
    {
      1: "st",
      2: "nd",
      3: "rd",
    }[value % 10] || "th";

  return value + suffix;
};

/**
 * Converts data from server to props needed by wine card
 * @param {Object} wineDetails
 * @return {Object}
 * */
const extractPropsForWineCard = (wineDetails) => {
  const NO_DECIMAL_PLACES = 0;
  const NO_THOUSAND_SEPARATOR = false;
  const { wineType, wineMaker } = wineDetails;
  const wineClass =
    wineType && wineType.wineClass
      ? wineType.wineClass
      : {
          id: WINE_CLASS_IDS.DB_ID_WINE_CLASS_ROSE,
          name: "",
        };
  const wineRegion = wineDetails.wineRegion || { name: "", state: null };
  const { isBookmarked, isLowStock } = wineDetails || {};
  return {
    name: wineDetails.wineType.name,
    wineClassId: wineClass.id,
    wineClassName: wineClass.name,
    wineMakerName: wineMaker.name,
    wineTypeId: wineType.id,
    region: wineRegion.name,
    regionState: wineRegion.state,
    country: wineDetails.country.name,
    title: wineDetails.product.name,
    slug: wineDetails.product.slug,
    price: wineDetails.product.sellingPrice,
    image: wineDetails.product.coverPhotoSmall || wineDetails.product.coverPhotoMedium,
    memberLikelihood:
      wineDetails.memberLikelihood &&
      formatNumber(100 * wineDetails.memberLikelihood, NO_DECIMAL_PLACES, NO_THOUSAND_SEPARATOR),
    memberWineRatingScore: wineDetails.memberWineRatingScore,
    isBookmarked,
    isLowStock,
  };
};

/**
 * Extract video Id from youtube url. Returns empty string if unable to match url
 *
 * @param {string} videoUrl
 * @returns {string}
 * @example extractIdFromYoutubeUrl('https://youtu.be/zMy8wXBU8Fs') // "zMy8wXBU8Fs"
 */
const extractIdFromYoutubeUrl = (videoUrl) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

  const match = videoUrl.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }

  return "";
};

/**
 * Extracts number from a string, and returns the number.
 *
 * @param {String} stringWithNumber
 * @returns {Number}
 * @example extractNumberFromString('80px') // 80
 * */
const extractNumberFromString = (stringWithNumber) => {
  const numbersArray = stringWithNumber.match(/(\d+)/) || [];
  const numberString = numbersArray.pop() || "0";
  const number = parseInt(numberString, 10);
  return number;
};

/**
 * Checks browser webp support.
 * @returns {boolean}
 */
const isWebpSupported = () => {
  const elem = typeof document === "object" ? document.createElement("canvas") : {};
  const dataElem = elem.toDataURL("image/webp");
  if (dataElem === null) {
    return false;
  }
  return dataElem.indexOf("data:image/webp") === 0;
};

/**
 * Converts the given string to Sentence Case.
 * @param {String} sentence
 * @returns {String}
 * */
const convertToSentenceCase = (sentence) => {
  if (!(typeof sentence === "string" || sentence instanceof String)) {
    return "";
  }
  if (WINE_COLLECTIONS_WITH_COUNTRY_NAMES[sentence]) {
    return WINE_COLLECTIONS_WITH_COUNTRY_NAMES[sentence];
  }
  const trimmedSentence = sentence.trim();
  const lowerCasedSentence = trimmedSentence.toLowerCase();
  return lowerCasedSentence.charAt(0).toUpperCase() + lowerCasedSentence.slice(1);
};

/**
 * Extracts relevant fields form memberSubscriptionQuery and returns if there is a pending billing order.
 *
 * @params {Object} memberSubscriptionQuery
 * @returns {Boolean}
 * */
const shouldShowChargeAndDispatchOrderNow = (memberSubscriptionQuery = {}) => {
  const { me } = memberSubscriptionQuery || {};
  const { subscription } = me || {};
  const { subscriptionStatus, orderOpenForChanges } = subscription || {};
  const { id } = subscriptionStatus || {};
  const isSubscriptionActive = id === SUBSCRIPTION_STATUS_NAME_TO_ID.ACTIVE;
  const showChargeAndDispatchOrderNow = isSubscriptionActive && orderOpenForChanges !== null;
  return showChargeAndDispatchOrderNow;
};

/**
 * Checks if the customers web browser is an unsupported browser,
 * like Internet Explorer, Yandex, with the following two approaches.
 *
 * Feature detection:
 * https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 *
 * Navigator's user agent:
 * https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
 *
 * And also checks if browser version is incompatible.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/finally
 *
 * @returns {Boolean}
 * */
const isUnSupportedBrowser = () => {
  // Internet Explorer 6-11 using feature detection.
  const isIE = false || !!document.documentMode;
  if (isIE) {
    return true;
  }

  // Using user agent.
  const { userAgent } = navigator || { userAgent: "" };
  const matches =
    userAgent.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [browserNameAndVersion, browserName, browserVersion] = matches || ["", "", ""];
  if (/trident/i.test(browserName)) {
    // Detects Internet Explorer using Navigator's user agent.
    return true;
  }

  // Detects Yandex web browser using Navigator's user agent.
  const isYandex = /yabrowser/.test(userAgent.toLowerCase());
  if (isYandex) {
    return true;
  }

  return false;
};

/**
 * Calculates the total horizontal space between the browsers window and <Container> component.
 *
 * @returns {Number}
 * */
const getContainerHorizontalWhiteSpace = () => {
  // <Container> component has padding-left and padding-right of 15px.
  const defaultHorizontalPadding = 30;

  try {
    const windowWidth = extractNumberFromString(`${window.innerWidth}`);

    if (windowWidth > BREAKPOINT.LG) {
      // Calculate the difference in width between the <Container> and browser window,
      // we add the defaultHorizontalPadding, to get the absolute difference in end-to-end width.
      const difference = windowWidth - CONTAINER_MAX_WIDTH.LG_UP + defaultHorizontalPadding;
      return difference > 0 ? difference : defaultHorizontalPadding;
    }
    if (windowWidth > BREAKPOINT.MD) {
      const difference = windowWidth - CONTAINER_MAX_WIDTH.MD_UP + defaultHorizontalPadding;
      return difference > 0 ? difference : defaultHorizontalPadding;
    }
    if (windowWidth > BREAKPOINT.SM) {
      const difference = windowWidth - CONTAINER_MAX_WIDTH.SM_UP + defaultHorizontalPadding;
      return difference > 0 ? difference : defaultHorizontalPadding;
    }
    if (windowWidth > BREAKPOINT.XS) {
      const difference = windowWidth - CONTAINER_MAX_WIDTH.XS_UP + defaultHorizontalPadding;
      return difference > 0 ? difference : defaultHorizontalPadding;
    }
    if (windowWidth < BREAKPOINT.XS) {
      return defaultHorizontalPadding;
    }
    return defaultHorizontalPadding;
  } catch {
    return defaultHorizontalPadding;
  }
};

/**
 * Checks if the resume quiz bar should be shown.
 *
 * @returns  {Boolean}
 * */
const shouldResumeBarBeShown = () => {
  try {
    const savedAnswers = window.localStorage.getItem("nonMemberQuizAnswersSelected");
    const isLoggedOut = !isLoggedIn();
    const answersExist = typeof savedAnswers === "string" || savedAnswers instanceof String;
    return isLoggedOut && answersExist;
  } catch (error) {
    logError(error);
    return false;
  }
};

/**
 * This function is called in handleScroll() handler,
 * and checks whether the customer is scrolling down or up.
 *
 * returns {Object}
 * */
const checkScrollingDown = (yValuePrev) => {
  // CASE: Show <Header>, if window scrolls until the height of header bar.
  const headerHeight = extractNumberFromString(header.desktopHeight);
  if (+window.scrollY <= headerHeight) {
    return { isScrollingDown: false, yValue: +window.scrollY };
  }

  // CASE: Window is scrolling up.
  if (+window.scrollY < yValuePrev) {
    return { isScrollingDown: false, yValue: +window.scrollY };
  }
  return { isScrollingDown: true, yValue: +window.scrollY };
};

/**
 * Gets the top position of <TabBar> in Sm Down screens.
 *
 * @param {Object} localShoppingCart
 * @param {Boolean} isHeaderShown
 * @returns {String}
 * */
const getTabBarSmDownTop = (localShoppingCartQuery, isHeaderShown) => {
  const { localShoppingCart } = localShoppingCartQuery || {};
  const { visible, items } = localShoppingCart || {};
  const smallScreenHasCartOnTop = visible && items && items.length > 0;
  const withTabBarMobileHeight = header.withTabBarMobileHeightBoxToggle;

  const withBoxAndCartTrayMobileHeight = header.withTabBarMobileHeightBoxToggle;

  if (isLoggedIn() && isHeaderShown) {
    return withBoxAndCartTrayMobileHeight;
  }
  if (isLoggedIn() && !isHeaderShown) {
    const boxCartHeight =
      extractNumberFromString(withTabBarMobileHeight) - extractNumberFromString(header.mobileHeight);
    return `${boxCartHeight}px`;
  }
  if (!isLoggedIn() && isHeaderShown) {
    if (smallScreenHasCartOnTop) {
      return withTabBarMobileHeight;
    }
    return header.mobileHeight;
  }
  if (!isLoggedIn() && !isHeaderShown) {
    if (smallScreenHasCartOnTop) {
      return "40px";
    }
    return "0px";
  }
  return "0px";
};

/*
 * Replaces invalid apostrophes for Concept-Regular text input fields.
 * In Gift note, surprise box gift note, one-off gift note and review and rate input fields.
 *
 * @param {String} text
 * @returns {String}
 * */
const replaceInvalidApostrophe = (text) => {
  try {
    return text ? text.replace(/'/g, "’") : "";
  } catch (error) {
    logError(error);
    return "";
  }
};

/**
 * Generates a random string to be used as the "name" attribute of a form element.
 * We usually need this to use in combination with "autocomplete" as "off" in order
 * to avoid browsers trying to autofill the element.
 *
 * @returns {String}
 * */
const generateRandomFormElementName = () => `random-input-${Math.floor(Math.random() * Math.floor(3000))}`;

/**
 * Formats the extraInfo field for gift voucher items
 * when local shopping cart items are synced to member's shopping cart on log in or sign up.
 *
 * @param {Object || null} extraInfo
 * @returns {Object || null}
 * */
const formatExtraInfoOnLocalShoppingCartSync = (extraInfo = {}) => {
  const { giftDeliveryId } = extraInfo || {};
  if (
    giftDeliveryId &&
    (giftDeliveryId === DB_ID_GIFT_DELIVERY_SEND_EMAIL || giftDeliveryId === DB_ID_GIFT_DELIVERY_DIY)
  ) {
    // CASE: Gift voucher is in local shopping cart.
    // Fields: bottlesPerBox, redBottles, whiteBottles, sparklingBottles, roseBottles
    // ...are required for GET_LOCAL_SHOPPING_CART.
    // However, they should be removed for gift vouchers on ADD_SHOPPING_CART_ITEM mutation.
    const { bottlesPerBox, redBottles, whiteBottles, sparklingBottles, roseBottles, ...diyGiftExtraInfo } =
      extraInfo || {};
    return diyGiftExtraInfo;
  }
  return extraInfo;
};

/**
 * Returns the announcements applicable for the current path.
 * @param {Array} announcements
 * @returns {Array}
 */
const getAnnouncementsForCurrentPath = (announcements) => {
  const { pathname } = window.location;
  const currentPath = pathname.endsWith("/") ? pathname : `${pathname}/`;
  return announcements.filter((announcement) => {
    const { targetedSlugs = [], excludedSlugs = [] } = announcement;
    if (targetedSlugs.length > 0) {
      return targetedSlugs.includes(currentPath);
    }
    return !excludedSlugs.includes(pathname);
  });
};

/**
 * Filters announcements and returns an array of those containing images.
 * Return array is sorted latest-first OR empty if no announcements contain images.
 *
 * @param {Object} getAnnouncements
 * @returns {Array}
 */
const getAnnouncementsWithImages = (getAnnouncements) => {
  if (!getAnnouncements) return [];

  const { allAnnouncements } = getAnnouncements;
  const filteredAnnouncements =
    allAnnouncements &&
    allAnnouncements.filter(
      (announcement) => announcement.imageMedium !== null && announcement.imageSmall !== null
    );
  if (filteredAnnouncements) {
    return filteredAnnouncements.slice().sort((x, y) => y.id - x.id);
  }

  return [];
};

/**
 * Checks if a given routeToTest is valid.
 *
 * @param {String} route
 */
const isValidRoute = (route) => {
  const country = getCountry();
  const routeToTest = route.startsWith(`/${country}`) ? route : `/${country}${route}`;
  let isValid = false;
  try {
    isValid = Object.values(urlPatterns).some((validRoute) => {
      if (typeof validRoute === "string" || validRoute instanceof String) {
        return validRoute === routeToTest;
      }
      if (typeof validRoute === "function" || validRoute instanceof Function) {
        let validRouteStaticPath = validRoute("");
        if (validRouteStaticPath && validRouteStaticPath.endsWith("//")) {
          validRouteStaticPath = validRouteStaticPath.slice(0, -1);
        }
        return routeToTest.startsWith(validRouteStaticPath);
      }

      return false;
    });
  } catch (error) {
    logError(error);
  }

  if (!isValid) {
    logError(
      `Invalid frontEndRoute ${routeToTest} returned from api. Please check and update in base admin.`
    );
  }
  return isValid;
};

/**
 * Checks if the window width of browser is lower or equal than small screen widths.
 *
 * @returns {Boolean}
 * */
const isSmallDevice = () => {
  const windowWidth = extractNumberFromString(`${window.innerWidth}`);
  return windowWidth <= BREAKPOINT.SM;
};

/**
 * Checks if the window width of browser is lower or equal than medium screen widths.
 *
 * @returns {Boolean}
 * */
const isMediumDevice = () => {
  const windowWidth = extractNumberFromString(`${window.innerWidth}`);
  return windowWidth <= BREAKPOINT.MD && !(windowWidth <= BREAKPOINT.SM);
};

/**
 * Checks if scroll-lock should be active for side trays in desktop smUp views.
 *
 * @param {Boolean} isScrollLockCurrentlyActive
 * @returns {Boolean}
 * */
const checkIfScrollLockInSideTrayShouldBeActive = (isScrollLockCurrentlyActive = false) => {
  let isScrollLockActive = isScrollLockCurrentlyActive;
  if (!isSmallDevice()) {
    isScrollLockActive = false;
  }
  return isScrollLockActive;
};

/**
 * Returns whether url parameters have '?is_mobile_app=true' parameter or not.
 * This parameter is used to disable header components
 * ...and hide <Footer> in Guides pages when opened in mobile app.
 *
 * @returns {Boolean}
 * */
const checkIfRequestIsFromMobileApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("is_mobile_app") === "true";
};

/**
 * Checks if new tag should be shown next on navigation bar's Taste Profile item.
 *
 * @param {Number} memberNavBarItemId
 * @returns {Boolean}
 * */
const shouldShowNewTagForWineProfile = (memberNavBarItemId) => {
  const isItemTasteProfile = memberNavBarItemId === MEMBER_NAVBAR_ITEM_ID_TASTE_PROFILE;
  const shouldShowNewTag = !window.localStorage.getItem(TASTE_PROFILE_VIEW_STORAGE_KEY);
  return shouldShowNewTag && isItemTasteProfile;
};

/**
 * Returns the correct country settings based on the URL.
 *
 * @returns {Object}
 * */
const getCountrySettings = () => {
  const country = getCountry();
  return COUNTRY_SPECIFIC_SETTINGS[country];
};

/**
 * Returns boolean that decides if checkoutV2 should be used.
 *
 * @returns {Boolean}
 * */
const shouldUseCheckoutV2 = () => {
  const { IS_COUNTRY_UK } = getCountrySettings();

  if (IS_COUNTRY_UK) {
    // Enable checkout v2 for UK
    return true;
  }

  if (IS_PROD) {
    // Disable checkout v2 in non UK production environment
    return false;
  }

  // Enable checkout v2 if there is a checkoutv2 query parameter in the URL in non prod environments
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("checkoutv2") != null;
};

const getUnleashConfig = () => {
  const { IS_COUNTRY_UK } = getCountrySettings();
  let url = process.env.REACT_APP_UNLEASH_PROXY_URL_AU;
  if (IS_COUNTRY_UK) {
    url = process.env.REACT_APP_UNLEASH_PROXY_URL_UK;
  }

  return {
    url,
    clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY, // defined when configuring proxy service at backend
    refreshInterval: 15,
    appName: "web",
    disableMetrics: true,
    environment: process.env.REACT_APP_ENV_NAME,
  };
};

/**
 * Sets Country ID of Logged in Member in Cache.
 * @param {Number} id
 * * */
const setCountryIdOfLoggedInMemberInCache = (id) => localStorage.setItem(COUNTRY_ID, id);

/**
 * Gets Country ID of Logged in Member in Cache.
 * @returns {Number}
 * * */
const getCountryIdOfLoggedInMemberInCache = () => parseInt(localStorage.getItem(COUNTRY_ID), 10) || 0;

/*
 * Returns a boolean to confirm if a provided object is empty or not.
 *
 * @param {Object} checkObj
 * @returns {Boolean}
 */
const isEmptyObject = (checkObj) => checkObj.constructor === Object && Object.keys(checkObj).length === 0;

/**
 * Returns the points of a challenge badge.
 *
 * @param {Object} challengeBadge
 * @returns {Number}
 * */
const getChallengeBadgePoints = (challengeBadge = {}) => {
  const { isEarned, badgePointsWhenEarned, points, memberBadge } = challengeBadge || {};

  if (isEarned) {
    return badgePointsWhenEarned || points || 0;
  }

  if (memberBadge) {
    return memberBadge.badgePointsWhenEarned || points || 0;
  }

  return points || 0;
};

const fetchReferralCodePromoDetails = async (referralCode) => {
  try {
    const response = await client.mutate({
      mutation: VALIDATE_REFERRAL_CODE,
      variables: {
        input: {
          referralCode,
        },
      },
    });
    const { data } = response || {};
    if (data) {
      return data.validateReferralCode;
    }
  } catch (error) {
    logError(error);
  }
  return null;
};

/**
 * Parses iso8601Durations.
 *
 * @param {String} iso8601Duration
 * @returns {Any}
 * */
const parseISO8601Duration = (iso8601Duration) => {
  const iso8601DurationRegex =
    // eslint-disable-next-line max-len
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  const matches = iso8601Duration.match(iso8601DurationRegex);
  return {
    sign: matches[1] === undefined ? "+" : "-",
    years: matches[2] === undefined ? 0 : matches[2],
    months: matches[3] === undefined ? 0 : matches[3],
    weeks: matches[4] === undefined ? 0 : matches[4],
    days: matches[5] === undefined ? 0 : matches[5],
    hours: matches[6] === undefined ? 0 : matches[6],
    minutes: matches[7] === undefined ? 0 : matches[7],
    seconds: matches[8] === undefined ? 0 : matches[8],
  };
};

/**
 * Stores Youtube data in cache for one hour.
 *
 * @param {Object} data
 * @param {String} key
 * */
export const storeYoutubeDataOneHour = (data, key) => {
  const now = new Date();
  const oneHourInMs = 3600000;

  const item = {
    value: data,
    expiry: now.getTime() + oneHourInMs,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Gets Youtube data if it was set less than one hour ago.
 *
 * @param {String} key
 * @returns {Array | null}
 * */
export const getYoutubeData = (key) => {
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > (item && item.expiry)) {
    // If the item is expired, delete the item from storage
    // and return null
    window.localStorage.removeItem(key);
    return null;
  }
  return (item && item.value) || null;
};

/**
 * Detects active navbarItem based on the current url.
 * @param {string} pathName
 * @returns {string}
 */
export const detectActiveNavbarItem = (pathName) => {
  const isWinesNavbarItemUrl = checkIfUrlIsInUrlGroup(pathName, WINES_NAVBAR_ITEM_URL_PATTERNS);
  if (isWinesNavbarItemUrl) {
    return NAVIGATION_WINES;
  }
  const isLearnNavbarItemUrl = checkIfUrlIsInUrlGroup(pathName, LEARN_NAVBAR_ITEM_URL_PATTERNS);
  if (isLearnNavbarItemUrl) {
    return NAVIGATION_LEARN;
  }
  const isGiftsNavbarItemUrl = checkIfUrlIsInUrlGroup(pathName, GIFTS_NAVBAR_ITEM_URL_PATTERNS);
  if (isGiftsNavbarItemUrl) {
    return NAVIGATION_GIFTS;
  }
  const isRewardsNavbarItemUrl = checkIfUrlIsInUrlGroup(pathName, REWARDS_NAVBAR_ITEM_URL_PATTERNS);
  if (isRewardsNavbarItemUrl) {
    return NAVIGATION_REWARDS;
  }
  return "";
};

/**
 * Checks if cart checkout should be disabled based on the cases listed below.
 * Case 1: >=3 one-off bottles: Enable button.
 * Case 2: 1 gift/special-pack, no bottles, and no merch: Enable button.
 * All other cases should disable the button.
 * @param {number} amountOneOffBottles
 * @param {number | null | undefined} minBottlesInOrder
 * @param {boolean} hasGift
 * @param {boolean} hasSpecialPack
 * @param {boolean} hasMerch
 * @returns {boolean}
 */
export const shouldDisableCartCheckout = (
  amountOneOffBottles,
  minBottlesInOrder,
  hasGift,
  hasSpecialPack,
  hasMerch
) => {
  const hasEnoughBottles = !minBottlesInOrder || amountOneOffBottles >= minBottlesInOrder;
  const hasNoBottles = amountOneOffBottles === 0;
  if (hasEnoughBottles) {
    return false;
  }
  if ((hasGift || hasSpecialPack) && hasNoBottles && !hasMerch) {
    return false;
  }
  return true;
};

/**
 * Checks which message should be shown to the user if cart checkout is disabled.
 * Case 1: User has rewards but not gift or special pack
 * Case 2: if they have special pack / gift and single bottles / rewards
 * All other cases: Return default message
 * @param {number} amountOneOffBottles
 * @param {boolean} hasGift
 * @param {boolean} hasSpecialPack
 * @param {boolean} hasMerch
 * @returns {string}
 */
export const getDisableCartCheckoutKey = (amountOneOffBottles, hasGift, hasSpecialPack, hasMerch) => {
  if (hasMerch && !hasGift && !hasSpecialPack) {
    return "case1";
  }
  if ((hasGift || hasSpecialPack) && (amountOneOffBottles > 0 || hasMerch)) {
    return "case2";
  }
  return "default";
};

/**
 * Checks if a number is a float.
 * @param {number}
 * @returns {boolean}
 */
export const isFloat = (number) => (number ?? 0) % 1 !== 0;

export {
  formatNumber,
  wait,
  getTimeGreeting,
  getWineBaseColor,
  ordinalNumberFormatter,
  extractPropsForWineCard,
  isSmallDevice,
  isMediumDevice,
  extractIdFromYoutubeUrl,
  extractNumberFromString,
  getUnleashConfig,
  isWebpSupported,
  convertToSentenceCase,
  shouldShowChargeAndDispatchOrderNow,
  isUnSupportedBrowser,
  getContainerHorizontalWhiteSpace,
  shouldResumeBarBeShown,
  checkScrollingDown,
  getTabBarSmDownTop,
  replaceInvalidApostrophe,
  generateRandomFormElementName,
  formatExtraInfoOnLocalShoppingCartSync,
  getAnnouncementsForCurrentPath,
  getAnnouncementsWithImages,
  isValidRoute,
  checkIfScrollLockInSideTrayShouldBeActive,
  checkIfRequestIsFromMobileApp,
  shouldShowNewTagForWineProfile,
  checkIfWineTypeIsOrangeWine,
  getCountrySettings,
  shouldUseCheckoutV2,
  setCountryIdOfLoggedInMemberInCache,
  getCountryIdOfLoggedInMemberInCache,
  isEmptyObject,
  getChallengeBadgePoints,
  fetchReferralCodePromoDetails,
  parseISO8601Duration,
};
