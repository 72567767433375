import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import iconClose from "../../../assets/images/icons/cross.svg";
import { colors, fonts, header } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { getAnnouncementsForCurrentPath } from "../../../helpers/tools";
import { GET_ANNOUNCEMENT, GET_AUTH, GET_LOCAL_SHOPPING_CART } from "../../../graphql/queries";
import { FETCH_POLICY_CACHE_ONLY } from "../../../helpers/constants";

const AnnouncementView = styled.div`
  background-color: ${colors.purpleThin};
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100vw;
  ${breakpoints.mdUp} {
    top: ${(props) => props.locateTop.mdUp};
  }
  ${breakpoints.mdDown} {
    top: ${(props) => props.locateTop.mdDown};
  }
  ${breakpoints.smDown} {
    top: ${(props) => props.smDown};
  }
  padding: 20px 0px;
`;

const CloseAnnouncement = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 0;
  background: url(${iconClose}) no-repeat 80% 80%;
`;

const AnnouncementTitle = styled.p`
  ${breakpoints.smUp} {
    font-size: 20px;
  }
  ${breakpoints.smDown} {
    font-size: 16px;
  }
  line-height: 20px;
  color: ${colors.black};
  font-family: ${fonts.fontInterstateBlackCompressed};
  text-align: center;
  padding: 0px 40px 0px;
  a {
    text-decoration: underline;
  }
`;

class AnnouncementBar extends PureComponent {
  renderTitle = (item, index) => {
    const { message, announcementBarUrl, announcementBarUrlText } = item || {};
    const hasLink = !!announcementBarUrl && !!announcementBarUrlText;

    return (
      <AnnouncementTitle key={index.toString()}>
        {`${message} `}
        {hasLink && (
          <a href={announcementBarUrl} rel="noopener noreferrer">
            {`${announcementBarUrlText}`}
          </a>
        )}
      </AnnouncementTitle>
    );
  };

  /**
   * Gets the height of announcement bar div, and sets it in <Layout>,
   * ...so, <Layout> can pass it to <BoxAndCartTray> Component to set its top location,
   * ...when, announcement bar is shown.
   *
   * @param {Object} domObj
   * */
  handleAnnouncementHeightForTray = (domObj) => {
    const { clientHeight } = domObj || {};
    const { setHeightOfAnnouncement } = this.props;
    if (clientHeight) {
      setHeightOfAnnouncement(clientHeight);
    } else {
      setHeightOfAnnouncement(0); // when object doesnt exist when scrolled up
    }
  };

  /**
   * Gets the Y value for top position of Announcement bar in smDown screens.
   * Takes care of cases with and without cart tabs,
   * and with and without pages with tab bars.
   *
   * @params {Boolean} smallScreenHasCartOnTop
   * @returns {String}
   * */
  getSmDownValue = (smallScreenHasCartOnTop) => {
    const { hasTabBarUnderHeader } = this.props;
    if (!smallScreenHasCartOnTop && !hasTabBarUnderHeader) {
      return "100%";
    }
    if (!smallScreenHasCartOnTop && hasTabBarUnderHeader) {
      const withTabBarMobileHeight = header.withTabBarMobileHeightBoxToggle;
      return withTabBarMobileHeight;
    }
    if (smallScreenHasCartOnTop && hasTabBarUnderHeader) {
      const withTabBarAndBoxCartTrayMobileHeight = header.withTabBarAndBoxCartTrayMobileHeightBoxToggle;
      return withTabBarAndBoxCartTrayMobileHeight;
    }
    const withBoxAndCartTrayMobileHeight = header.withBoxAndCartTrayMobileHeightBoxToggle;
    return withBoxAndCartTrayMobileHeight;
  };

  render() {
    const {
      locateTop,
      closeAnnouncementHandler,
      getAnnouncements,
      isLoggedIn,
      showBoxAndCartTray,
      getLocalShoppingCart,
    } = this.props;

    if (getAnnouncements.loading || getLocalShoppingCart.loading) {
      return null;
    }

    const { localShoppingCart } = getLocalShoppingCart || {};
    const { visible, items } = localShoppingCart || {};
    const smallScreenHasCartOnTop =
      (isLoggedIn && showBoxAndCartTray) || (!isLoggedIn && visible && items && items.length > 0);

    const { allAnnouncements } = getAnnouncements;
    if (allAnnouncements === null || allAnnouncements === [] || allAnnouncements === undefined) {
      return null;
    }
    const announcementsForCurrentPath = getAnnouncementsForCurrentPath(allAnnouncements);
    // filters the 3 most recent enabled announcements (removing image announcements)
    const textAnnouncements = announcementsForCurrentPath.filter(
      (announcement) => announcement.imageMedium === null && announcement.imageSmall === null
    );
    const sortedAnnouncements = textAnnouncements.slice().sort((x, y) => y.id - x.id);
    const slicedAnnouncements = sortedAnnouncements.slice(0, 3);

    // get the id of the most recent announcement to store it locally if users close announcement
    const recentAnnouncement = slicedAnnouncements[0];
    const { id } = recentAnnouncement || {};

    // if previously closed announcementID is equal to current recent announcement id,
    // dont show announcements
    const closedId = window.localStorage.getItem("maxIndexOfAnnouncementClosed");
    if (closedId && id && closedId.toString() === id.toString()) {
      return null;
    }

    if (slicedAnnouncements.length > 0) {
      return (
        <AnnouncementView
          locateTop={locateTop}
          smDown={this.getSmDownValue(smallScreenHasCartOnTop)}
          ref={(domObj) => this.handleAnnouncementHeightForTray(domObj)}
        >
          <CloseAnnouncement
            onClick={() => {
              window.localStorage.setItem("maxIndexOfAnnouncementClosed", id);
              closeAnnouncementHandler();
            }}
          />
          {slicedAnnouncements.map((item, index) => this.renderTitle(item, index))}
        </AnnouncementView>
      );
    }

    return null;
  }
}

AnnouncementBar.propTypes = {
  locateTop: PropTypes.shape({}).isRequired,
  closeAnnouncementHandler: PropTypes.func.isRequired,
  getAnnouncements: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showBoxAndCartTray: PropTypes.bool.isRequired,
  setHeightOfAnnouncement: PropTypes.func.isRequired,
  getLocalShoppingCart: PropTypes.shape({}).isRequired,
  hasTabBarUnderHeader: PropTypes.bool.isRequired,
};

export default compose(
  graphql(GET_AUTH, {
    name: "authQuery",
    options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
  }),
  graphql(GET_ANNOUNCEMENT, {
    name: "getAnnouncements",
    options: (props) => ({
      variables: {
        memberId: props.authQuery.auth.memberId,
      },
    }),
  }),
  graphql(GET_LOCAL_SHOPPING_CART, { name: "getLocalShoppingCart" })
)(AnnouncementBar);
