import React, { useState, useCallback, useEffect, useMemo } from "react";

// @ts-ignore
import cn from "classnames";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { initializeWithConfig, getInAppMessages, DisplayOptions } from "@iterable/web-sdk";
import { useFlag } from "@unleash/proxy-client-react";
import { AxiosError } from "axios";

import { FacebookPixel } from "react-use-facebook-pixel";
import {
  GET_GUEST_PROMO_CODE_DISCOUNT,
  GET_LOCAL_SHOPPING_CART,
  GET_MEMBER_LAYOUT_INFO,
  GET_REFERRAL_DISCOUNT,
} from "../../../graphql/queries";
import {
  SUBSCRIPTION_STATUS_NAME_TO_ID,
  USER_HAS_ACCEPTED_COOKIE_BOX,
  HEADER_MENU_NAME_MAX_LENGTH,
  I18N_NAMESPACES,
  REACT_APP_ITERABLE_API_KEY,
  REACT_APP_ITERABLE_APP_NAME,
  UNLEASH_ITERABLE_MESSAGING,
  ERROR_CODE_ITERABLE_FORGOTTEN_USER,
  REACT_APP_FACEBOOK_PIXEL_ID,
  IS_PROD,
  CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY,
  CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY_TTL,
} from "../../../helpers/constants";
import ModalContainer from "../../molecules/ModalContainer";
import WelcomePackWarning from "../../molecules/WelcomePackWarning";

import Footer from "../Footer";
import Header from "../Header";
import ShippingDatesNotice from "../../atoms/ShippingDatesNotice";
import AnnouncementPopup from "../AnnouncementPopup";
import BoxAndCartTray from "../BoxAndCartTray";
import ResumeQuizBar from "../../atoms/ResumeQuizBar";
import LogInPanel from "../LogInPanel";
import { useDeviceSize, useInitUnleashClient } from "../../../helpers/hooks";
import { checkIfRequestIsFromMobileApp, extractNumberFromString } from "../../../helpers/tools";
import {
  UK_COUNTRY_CODE,
  getCountry,
  offerToChangeCountry,
  getCountryRemovedUrl,
  checkIfUrlIsInUrlGroup,
} from "../../../helpers/urls.helper";
import ChangeLanguagePopup from "../../molecules/ChangeLanguagePopup";
import GdprPrivacyBox from "../../molecules/GdprPrivacyBox";
import { colors, header } from "../../../styles/variables";
import { isLoggedIn } from "../../../helpers/auth";
import { logError } from "../../../helpers/logging";
import StyledLoading from "../../atoms/Loading/StyledLoading";
import { GuestPromoCodeDiscount } from "../../../types/__generated__/GuestPromoCodeDiscount";
import { ReferralDiscount } from "../../../types/__generated__/ReferralDiscount";
import { MemberLayoutInfo } from "../../../types/__generated__/MemberLayoutInfo";
import { LocalShoppingCart } from "../../../types/__generated__/LocalShoppingCart";
import URL_PATTERNS from "../../../urls";
import { getWithExpiry, setWithExpiry } from "../../../helpers/storage";
import { URL_PATTERNS_SHOW_CHRISTMAS_CUT_OFF } from "../../../helpers/urls.constants";
import { GlobalStyle, BoxHolder } from "./style";
import { AppContext, LayoutContext } from "./context";
import { DEFAULT_NOTIFICATION_LOCATIONS } from "./helper";

interface LayoutProps {
  isHeaderTransparent?: boolean;
  showAnnouncementBar?: boolean;
  showBoxAndCartTray?: boolean;
  hasTabBarUnderHeader?: boolean;
  showAnnouncementPopupForSubscribers?: boolean;
  showAnnouncementPopupForNonSubscribers?: boolean;
  className?: string;
  children: React.ReactNode;
  variant?: "home" | "standard";
}

const Layout = ({
  showBoxAndCartTray = true,
  showAnnouncementBar = false,
  showAnnouncementPopupForSubscribers = false,
  showAnnouncementPopupForNonSubscribers = false,
  hasTabBarUnderHeader = false,
  className,
  children,
  variant = "standard",
}: LayoutProps) => {
  const { pathname } = useLocation();
  const userIsLoggedIn = isLoggedIn();

  const { t } = useTranslation([I18N_NAMESPACES.GIFTS]);
  const { isSmall } = useDeviceSize();

  const isIterableMessagingEnabled = useFlag(UNLEASH_ITERABLE_MESSAGING);

  const hasUserSeenChristmasCutOffPopup = !getWithExpiry(CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY);
  const [showShippingDatesNotice, setShowShippingDatesNotice] = useState<boolean>(false);

  const [showHeaderBarFromChild, setShowHeaderBarFromChild] = useState<boolean>(false);
  const [shouldOpenBoxAndCartTray, setShouldOpenBoxAndCartTray] = useState<boolean>(false);

  const { logout, setEmail } = initializeWithConfig({
    authToken: REACT_APP_ITERABLE_API_KEY,
    configOptions: { dangerouslyAllowJsPopups: true },
    generateJWT: () => setIterableJwtToken(),
  });

  const {
    data: memberLayoutInfoData,
    refetch: refetchMemberLayoutInfo,
    loading: isMemberLayoutInfoLoading,
  } = useQuery<MemberLayoutInfo>(GET_MEMBER_LAYOUT_INFO, {
    variables: {
      skipMe: !userIsLoggedIn,
    },
  });

  const { data: localShoppingCartData } = useQuery<LocalShoppingCart>(GET_LOCAL_SHOPPING_CART, {
    skip: userIsLoggedIn,
  });
  const { data: guestMemberDiscountsData } = useQuery<GuestPromoCodeDiscount>(GET_GUEST_PROMO_CODE_DISCOUNT, {
    skip: userIsLoggedIn,
  });
  const { data: guestMemberReferralDiscountsData } = useQuery<ReferralDiscount>(GET_REFERRAL_DISCOUNT, {
    skip: userIsLoggedIn,
  });

  const [isBoxCartTrayOpened, setIsBoxCartTrayOpened] = useState<boolean>(false);
  const [heightOfAnnouncement, setHeightOfAnnouncement] = useState<number>(0);
  const [isLoginPanelShown, setIsLoginPanelShown] = useState<boolean>(false);
  const [showWelcomePackWarning, setShowWelcomePackWarning] = useState<boolean>(false);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(true);
  const [showOfferToChangeCountry, setShowOfferToChangeCountry] = useState<boolean>(false);
  const [isAnnouncementPopupShown, setIsAnnouncementPopupShown] = useState<boolean>(false);
  const [showCookieBox, setShowCookieBox] = useState<boolean>(true);
  const [isIterableReady, setIsIterableReady] = useState<boolean>(false);
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixel | null>(null);

  useInitUnleashClient();

  const country = getCountry();

  const handleChangeHeightOfAnnouncement = (height: number) => {
    setHeightOfAnnouncement(
      hasTabBarUnderHeader && !isBoxCartTrayOpened
        ? height + extractNumberFromString(header.tabBarHeight)
        : height
    );
  };

  const handleToggleWelcomePackWarning = () => {
    setIsBoxCartTrayOpened(!showWelcomePackWarning && isBoxCartTrayOpened);
    setShowWelcomePackWarning(!showWelcomePackWarning);
  };

  const checkIfShouldOfferToChangeCountry = useCallback(async () => {
    setShowOfferToChangeCountry(await offerToChangeCountry());
  }, []);

  const setIterableJwtToken = () => {
    const { iterableJwtToken } = memberLayoutInfoData?.me || {};
    return Promise.resolve(iterableJwtToken || "");
  };

  // This effect makes the window scroll to top at every page change.
  useEffect(() => {
    window.scrollTo(0, 0);

    // Since showHeaderBarFromChild is set to true on some pages, set both
    // variables back to false when changing pages, so that the new page does not inherit the old state.
    setShowHeaderBarFromChild(false);

    const shouldNavigateToDashboard = pathname === URL_PATTERNS.HOME && userIsLoggedIn;

    if (shouldNavigateToDashboard) {
      history.push(URL_PATTERNS.MY_DASHBOARD);
    }

    // Chistmas cut off dates view
    const shouldShowChristmasCutOffPage = checkIfUrlIsInUrlGroup(
      pathname,
      URL_PATTERNS_SHOW_CHRISTMAS_CUT_OFF
    );

    const shouldShowShippingDatesNotice =
      shouldShowChristmasCutOffPage && hasUserSeenChristmasCutOffPopup && !showOfferToChangeCountry;

    if (shouldShowShippingDatesNotice) {
      setShowShippingDatesNotice(shouldShowShippingDatesNotice);
      setWithExpiry(
        CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY,
        true,
        CHRISTMAS_CUT_OFF_DATES_VIEW_STORAGE_KEY_TTL
      );
    }
  }, [pathname]);

  useEffect(() => {
    checkIfShouldOfferToChangeCountry();
  }, [checkIfShouldOfferToChangeCountry]);

  // Set up Iterable
  useEffect(() => {
    const isMemberLayoutDataLoaded = !isMemberLayoutInfoLoading && !!memberLayoutInfoData?.me;
    const { email } = memberLayoutInfoData?.me || {};
    // Only initialize Iterable when it's not yet ready
    const shouldSetUserIterableEmail =
      isMemberLayoutDataLoaded && userIsLoggedIn && isIterableMessagingEnabled && email;

    if (shouldSetUserIterableEmail) {
      // Identify user
      setEmail(email).then(() => {
        setIsIterableReady(true);
      });
    }
  }, [memberLayoutInfoData, userIsLoggedIn]);

  // Log out from Iterable
  useEffect(() => {
    if (!userIsLoggedIn) {
      logout();
    }
  }, [userIsLoggedIn]);

  useEffect(() => {
    const pixel = new FacebookPixel({
      pixelID: REACT_APP_FACEBOOK_PIXEL_ID,
      debug: !IS_PROD,
      pageViewOnInit: true,
    });

    pixel.init({});

    setFacebookPixel(pixel);
  }, []);

  // Iterable in-browser messages
  // Fetch when app is loaded and when users navigate to a new page
  useEffect(() => {
    if (isIterableReady) {
      // All params: request, pauseMessageStream, resumeMessageStream, triggerDisplayMessages
      const { request, triggerDisplayMessages } = getInAppMessages(
        {
          // Here, configure the SDK. For more information, check out the
          // web SDK's GitHub repository: https://github.com/iterable/iterable-web-sdk
          count: 20,
          displayInterval: 1000,
          packageName: REACT_APP_ITERABLE_APP_NAME,
        },
        { display: DisplayOptions.Deferred }
      );

      request()
        .then((response) => {
          const unreadMessages = response.data.inAppMessages.filter((message) => !message.read);
          const countryRemovedPath = getCountryRemovedUrl(pathname);
          const defaultLocations = DEFAULT_NOTIFICATION_LOCATIONS.map((path: string) =>
            getCountryRemovedUrl(path)
          );
          // If webLocations are specified, only display message if match with current path
          // If not, show message for pre-defined pages
          const unreadMessagesForCurrentPath = unreadMessages.filter((message) => {
            const locations = message.customPayload?.webLocations || defaultLocations;
            return locations?.includes(countryRemovedPath);
          });

          triggerDisplayMessages(unreadMessagesForCurrentPath);
        })
        .catch((error: AxiosError) => {
          if (error?.response) {
            const iterableCode = error.response?.data?.code || "";

            if (iterableCode === ERROR_CODE_ITERABLE_FORGOTTEN_USER) {
              setIsIterableReady(false);
              return;
            }

            if (iterableCode) {
              logError(`Iterable Error: ${iterableCode}`);
              return;
            }

            logError(
              `Iterable responded with status code: ${error.response?.status}, data: ${JSON.stringify(
                error.response?.data
              )}`
            );
          }
        });
    }
  }, [isIterableReady, pathname]);

  const formattedUserName = useMemo(() => {
    const { firstName, email } = memberLayoutInfoData?.me || {};
    const name = firstName || email || "";

    if (name.length <= HEADER_MENU_NAME_MAX_LENGTH) {
      return name;
    }
    const displayName = name.substring(0, HEADER_MENU_NAME_MAX_LENGTH);
    return `${displayName}...`;
  }, [memberLayoutInfoData?.me]);

  useEffect(() => {
    const hasPromoCode =
      !!memberLayoutInfoData?.me?.shoppingCart?.discountCode ||
      !!guestMemberDiscountsData?.guestPromoCodeDiscount?.promoCode ||
      !!guestMemberReferralDiscountsData?.referralDiscount?.referralCode;

    if (hasPromoCode) {
      setIsAnnouncementPopupShown(false);
    } else if (showAnnouncementPopupForSubscribers && showAnnouncementPopupForNonSubscribers) {
      setIsAnnouncementPopupShown(true);
    } else if (showAnnouncementPopupForSubscribers || showAnnouncementPopupForNonSubscribers) {
      const subscription = memberLayoutInfoData?.me?.subscription;
      const hasSubscribedBefore =
        !!subscription &&
        subscription.subscriptionStatus.id !== SUBSCRIPTION_STATUS_NAME_TO_ID.NEVER_ACTIVATED;

      // Showing announcement popup to subscribed members
      if (hasSubscribedBefore && showAnnouncementPopupForSubscribers) setIsAnnouncementPopupShown(true);

      // Showing announcement popup to non-subscribed members
      if (!hasSubscribedBefore && showAnnouncementPopupForNonSubscribers) setIsAnnouncementPopupShown(true);
    }
  }, [
    guestMemberDiscountsData,
    guestMemberReferralDiscountsData,
    memberLayoutInfoData,
    showAnnouncementPopupForSubscribers,
    showAnnouncementPopupForNonSubscribers,
  ]);

  // If url is set to /UK/ or the userCountry is set to a EU country in the cookies
  // and the user hasn't accepted the the cookie consent box.
  const userHasAcceptedCookieBox = window.localStorage.getItem(USER_HAS_ACCEPTED_COOKIE_BOX);
  const shouldShowCookiesBox = country === UK_COUNTRY_CODE && !userHasAcceptedCookieBox && showCookieBox;

  const showAnnouncementPopup = !isLoginPanelShown && !isBoxCartTrayOpened && isAnnouncementPopupShown;

  const disableHeaderComponents = isSmall && checkIfRequestIsFromMobileApp();
  const shouldShowFooter = !disableHeaderComponents;
  const history = useHistory();

  const hasUnreadRecentNotifications = memberLayoutInfoData?.me?.hasUnreadRecentNotifications ?? false;
  const { me, minBottlesForFreeShipping, minBottlesInOrder, collections } = memberLayoutInfoData || {};
  const {
    id: memberId,
    points: memberTotalPoints,
    hasTakenTheQuiz: memberHasTakenTheQuiz,
    firstName: memberFirstName,
    hasDownloadedApp: memberHasDownloadedMobileApp,
    totalChargedOrdersExcludingGifts: memberTotalChargedPersonalOrders,
    level,
  } = me || {};
  const { rank: memberCurrentLevel, name: memberCurrentLevelName } = level || {};
  const shouldWinesBeAddedToSubscription = me?.shoppingCart?.shouldWinesBeAddedToSubscription ?? false;
  let totalPriceOnToggleButton;
  if (shouldWinesBeAddedToSubscription) {
    totalPriceOnToggleButton = memberLayoutInfoData?.me?.subscription?.netTotal ?? 0;
  } else {
    totalPriceOnToggleButton = userIsLoggedIn
      ? memberLayoutInfoData?.me?.shoppingCart?.total ?? 0
      : localShoppingCartData?.localShoppingCart?.totalProductsCost ?? 0;
  }

  const layoutContextValue = useMemo(
    () => ({
      setShowHeaderBarFromChild,
      setShouldOpenBoxAndCartTray,
      showHeaderBarFromChild,
    }),
    [showHeaderBarFromChild, shouldOpenBoxAndCartTray]
  );

  const appContextValue = useMemo(
    () => ({
      memberId,
      memberTotalPoints,
      memberHasTakenTheQuiz,
      memberFirstName,
      memberCurrentLevel,
      memberCurrentLevelName,
      memberHasDownloadedMobileApp,
      memberTotalChargedPersonalOrders,
      minBottlesForFreeShipping,
      minBottlesInOrder,
      shouldWinesBeAddedToSubscription,
      collections,
      isIterableReady,
      facebookPixel,
    }),
    [memberLayoutInfoData, isIterableReady, facebookPixel]
  );

  const isHeaderTransparent = variant === "home";
  const currentHeader = (
    <Header
      isHeaderTransparent={isHeaderTransparent}
      showAnnouncementBar={showAnnouncementBar}
      showBoxAndCartTray={showBoxAndCartTray}
      setHeightOfAnnouncement={handleChangeHeightOfAnnouncement}
      onClickSignIn={setIsLoginPanelShown}
      setHeaderDisplay={setIsHeaderShown}
      isHeaderShown={showHeaderBarFromChild || isHeaderShown}
      hasTabBarUnderHeader={hasTabBarUnderHeader}
      isBoxCartTrayOpened={isBoxCartTrayOpened}
      showHeaderBarFromChild={showHeaderBarFromChild}
      hasUnreadRecentNotifications={hasUnreadRecentNotifications}
      refetchMemberLayoutInfoData={refetchMemberLayoutInfo}
      firstName={formattedUserName}
      memberLayoutInfoData={memberLayoutInfoData}
    />
  );

  const shouldShowBoxAndCartTray = showBoxAndCartTray && !disableHeaderComponents;

  return (
    <AppContext.Provider value={appContextValue}>
      <LayoutContext.Provider value={layoutContextValue}>
        <GlobalStyle />
        {!disableHeaderComponents && currentHeader}
        <main className={cn("content", className)}>
          {shouldShowBoxAndCartTray && (
            <BoxHolder isHeaderShown={showHeaderBarFromChild || isHeaderShown}>
              {!isMemberLayoutInfoLoading && (
                // Prevent the initial display of the one-off cart selection, which can lead to a confusing
                // user experience when the query eventually loads and switches to the monthly box selection.
                <BoxAndCartTray
                  heightOfAnnouncement={heightOfAnnouncement}
                  shouldOpenBoxAndCartTray={shouldOpenBoxAndCartTray}
                  setShouldOpenBoxAndCartTray={setShouldOpenBoxAndCartTray}
                  showHeaderBarFromChild={showHeaderBarFromChild}
                  isHeaderShown={isHeaderShown}
                  setHeaderDisplay={setIsHeaderShown}
                  setBoxCartTrayDisplay={setIsBoxCartTrayOpened}
                  toggleWelcomePackWarning={handleToggleWelcomePackWarning}
                  showWelcomePackWarning={showWelcomePackWarning}
                  shouldWinesBeAddedToSubscription={shouldWinesBeAddedToSubscription}
                  totalPriceOnToggleButton={totalPriceOnToggleButton}
                  memberLayoutInfoData={memberLayoutInfoData}
                />
              )}
            </BoxHolder>
          )}
          {showOfferToChangeCountry && (
            <ChangeLanguagePopup closePopupHandler={() => setShowOfferToChangeCountry(false)} />
          )}
          {showAnnouncementPopup && !showOfferToChangeCountry && !showShippingDatesNotice && (
            <AnnouncementPopup closeAnnouncementHandler={() => setIsAnnouncementPopupShown(false)} />
          )}
          <React.Suspense fallback={<StyledLoading />}>{children}</React.Suspense>
        </main>
        {shouldShowFooter && (
          <>
            <Footer />
            <ResumeQuizBar />
          </>
        )}
        {isLoginPanelShown && <LogInPanel loginPanelHandler={setIsLoginPanelShown} />}
        {shouldShowCookiesBox && <GdprPrivacyBox acceptCookiesHandler={() => setShowCookieBox(false)} />}
        {showWelcomePackWarning && (
          <ModalContainer
            isOpened={showWelcomePackWarning}
            outsideClickHandler={() => setShowWelcomePackWarning(false)}
            backgroundColor={colors.tanThin}
            isScrollLockEnabled={showWelcomePackWarning}
            fullViewMask
            isModalMdUpHeightAuto
            modalSidePadding={0}
          >
            <WelcomePackWarning history={history} toggleWelcomePackWarning={handleToggleWelcomePackWarning} />
          </ModalContainer>
        )}
        {showShippingDatesNotice && !showOfferToChangeCountry && (
          <ModalContainer
            isOpened={showShippingDatesNotice}
            outsideClickHandler={() => setShowShippingDatesNotice(!showShippingDatesNotice)}
            title={t("gifts:shippingDatesModalTitle.true")}
            isScrollLockEnabled={showShippingDatesNotice}
          >
            <ShippingDatesNotice />
          </ModalContainer>
        )}
      </LayoutContext.Provider>
    </AppContext.Provider>
  );
};

export default Layout;
